import { gql, MutationHookOptions, QueryHookOptions, useMutation, useQuery } from '@apollo/client'
import {
  BasketFullProductsArgs,
  BasketInsightsArgs,
  GetBasketContextInput,
  Mutation,
  MutationArchiveBasketArgs,
  MutationCopyCurvoBasketArgs,
  MutationEditSmartBasketArgs,
  MutationNewSmartBasketArgs,
  MutationProcessBasketUploadArgs,
  MutationRemoveBasketProductsArgs,
  MutationUnarchiveBasketArgs,
  MutationUpdateSmartBasketConfigArgs,
  MutationUploadBasketFileArgs,
  Query,
  QueryBasketArgs,
  QueryBasketInsightsArgs,
  QueryBasketsArgs,
  QueryContractBasketArgs,
  QueryFeaturedBasketsArgs,
  QueryNormalizedSupplierNamesForUploadedFileColumnArgs,
} from '../gen/models'

export const BasketComplexityFragment = gql`
  fragment BasketComplexityFragment on Complexity {
    overallLevel
    metrics {
      name
      level
      recordedMeasurement
    }
    measuredAt
  }
`

export const SimpleBasketFragment = gql`
  fragment SimpleBasketFragment on Basket {
    id
    name
    numItems
    status
    isCatalogBasket
    isSearchAll
    catalogId
    totalSpend
    percentiles
    type
    typeLabel
    archivedAt
    complexity {
      ...BasketComplexityFragment
    }
  }
  ${BasketComplexityFragment}
`

export const BasketFragment = gql`
  fragment BasketFragment on Basket {
    ...SimpleBasketFragment
    createdAt
    updatedAt
    creator {
      id
      firstName
      lastName
    }
    smartBasketConfig {
      allowOverwrite
      facilityIds
    }
    isContractBasket
    contract {
      id
    }
    customer {
      id
    }
    calculatedDashboards {
      id
      name
      description
      icon
      backgroundColor
    }
  }
  ${SimpleBasketFragment}
`

const BASKETS_QUERY = gql`
  query baskets($input: GetBasketsByCriteriaInput!, $pagination: Pagination) {
    baskets(input: $input, pagination: $pagination) {
      totalHits
      hits {
        ...SimpleBasketFragment
        insights12m {
          numItems
          totalSpend
          opportunity25
          opportunity50
          opportunity75
          opportunity90
          percentiles
          projectedSpend
          topIncumbent {
            totalSpend
            name
            percentage
          }
        }
        likelihoods {
          priceScoreLikelihoods
          totalSpend
        }
      }
    }
  }
  ${SimpleBasketFragment}
`

export function useBasketsQuery(basicOptions: QueryHookOptions<Pick<Query, 'baskets'>, QueryBasketsArgs>) {
  return useQuery(BASKETS_QUERY, basicOptions)
}

// Minimal basket information needed to render the baskets selector modal
const BASKETS_SELECTOR_MODAL_QUERY = gql`
  query basketsSelectorModal($input: GetBasketsByCriteriaInput!, $pagination: Pagination) {
    baskets(input: $input, pagination: $pagination) {
      totalHits
      hits {
        id
        opportunitiesUsedIn
        name
        createdAt
        insights {
          totalSpend
          numItems
        }
      }
    }
  }
`

export function useBasketsSelectorModalQuery(basicOptions: QueryHookOptions<Pick<Query, 'baskets'>, QueryBasketsArgs>) {
  return useQuery(BASKETS_SELECTOR_MODAL_QUERY, basicOptions)
}

const BASKETS_WITH_INSIGHTS_QUERY = gql`
  query basketsWithInsights(
    $input: GetBasketsByCriteriaInput!
    $spendDateRange: SpendDateRangeInput
    $pagination: Pagination
  ) {
    baskets(input: $input, pagination: $pagination) {
      totalHits
      hits {
        opportunitiesUsedIn
        insights(spendDateRange: $spendDateRange) {
          totalSpend
          numItems
        }
        ...BasketFragment
      }
    }
  }
  ${BasketFragment}
`

export function useBasketsWithInsightsQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'baskets'>, QueryBasketsArgs & BasketInsightsArgs>,
) {
  return useQuery(BASKETS_WITH_INSIGHTS_QUERY, basicOptions)
}

const BASKET_QUERY = gql`
  query basket($input: IDArgs!) {
    basket(input: $input) {
      basket {
        ...BasketFragment
      }
    }
  }
  ${BasketFragment}
`

export function useBasketQuery(basicOptions: QueryHookOptions<Pick<Query, 'basket'>, QueryBasketArgs>) {
  return useQuery(BASKET_QUERY, basicOptions)
}

const SIMPLE_BASKET_INSIGHTS_QUERY = gql`
  query simpleBasketWithInsights($input: IDArgs!) {
    basket(input: $input) {
      basket {
        id
        name
        typeLabel
        numItems
        createdAt
        creator {
          firstName
          lastName
        }
        insights {
          numItems
          totalSpend
          facilityIds
          opportunity25
          opportunity50
          opportunity75
          opportunity90
          projectedSpend
          topIncumbent {
            totalSpend
            name
            percentage
          }
        }
      }
    }
  }
`

export function useSimpleBasketWithInsightsQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'basket'>, QueryBasketArgs>,
) {
  return useQuery(SIMPLE_BASKET_INSIGHTS_QUERY, basicOptions)
}

const BASKET_QUERY_CONTEXT = gql`
  query basketContext($input: IDArgs!, $aggregateType: BasketContextAggregateType!) {
    basket(input: $input) {
      basket {
        id
        context(input: { aggregateType: $aggregateType }) {
          aggregateId
          aggregateName
          normalizedManufacturer
          manufacturerTotal
          marketShare
          percentiles
          priceScore
        }
        contextStats {
          catalogId
          top3VendorsAvgMarketShare {
            normalizedManufacturer
            avgMarketShare
          }
          avgMarketShareTopVendor
          pricingTrend
        }
      }
    }
  }
`

export function useBasketContextQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'basket'>, QueryBasketArgs & GetBasketContextInput>,
) {
  return useQuery(BASKET_QUERY_CONTEXT, basicOptions)
}

const BASKET_FULL_PRODUCTS_QUERY = gql`
  query basketWithFullProducts($input: IDArgs!, $pagination: Pagination) {
    basket(input: $input) {
      basket {
        id
        fullProducts(pagination: $pagination) {
          data {
            tsId
            facilityId
            facilityName
            esSupplierNormalized
            esProductNormalized
            esVendorNormalized
            esVendorItemNormalized
            esDescription
            vendor
            facilityItem
            vendorItem
            manufacturer
            manufacturerItem
            description
            uom
            uomConversion
            lastPurchaseDate
            totalSpend
            totalQuantity
            currentPrice
            last12MonthsSpend
            last12MonthsQuantity
          }
          total
        }
      }
    }
  }
`

export function useBasketFullProductsQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'basket'>, QueryBasketArgs & BasketFullProductsArgs>,
) {
  return useQuery(BASKET_FULL_PRODUCTS_QUERY, basicOptions)
}

const CONTRACT_BASKET_QUERY = gql`
  query contractBasket($input: ContractBasketInput!) {
    contractBasket(input: $input) {
      ...BasketFragment
    }
  }
  ${BasketFragment}
`

export function useContractBasketQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'contractBasket'>, QueryContractBasketArgs>,
) {
  return useQuery(CONTRACT_BASKET_QUERY, basicOptions)
}

const NEW_SMART_BASKET = gql`
  mutation newSmartBasket($input: CreateSmartBasketInput!) {
    newSmartBasket(input: $input) {
      basket {
        ...BasketFragment
      }
    }
  }
  ${BasketFragment}
`

const BASKET_INSIGHTS_QUERY = gql`
  query basketInsights($input: IDArgs!) {
    basketInsights(input: $input) {
      numItems
      totalSpend
      facilityIds
      opportunity25
      opportunity50
      opportunity75
      opportunity90
      projectedSpend
      topIncumbent {
        totalSpend
        name
        percentage
      }
      processingJobId
    }
  }
`

export function useBasketInsightsQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'basketInsights'>, QueryBasketInsightsArgs>,
) {
  return useQuery(BASKET_INSIGHTS_QUERY, basicOptions)
}

export function useNewSmartBasketMutation() {
  return useMutation<Pick<Mutation, 'newSmartBasket'>, MutationNewSmartBasketArgs>(NEW_SMART_BASKET, {})
}

const EDIT_SMART_BASKET = gql`
  mutation editSmartBasket($input: UpdateSmartBasketInput!) {
    editSmartBasket(input: $input) {
      basket {
        ...BasketFragment
      }
      updateMetricsJobId
    }
  }
  ${BasketFragment}
`

export function useEditSmartBasketMutation() {
  return useMutation<Pick<Mutation, 'editSmartBasket'>, MutationEditSmartBasketArgs>(EDIT_SMART_BASKET, {})
}

const REMOVE_BASKET_PRODUCTS = gql`
  mutation removeBasketProducts($input: RemoveBasketProductsInput!) {
    removeBasketProducts(input: $input) {
      updateMetricsJobId
    }
  }
`

export function useRemoveBasketProductsMutation() {
  return useMutation<Pick<Mutation, 'removeBasketProducts'>, MutationRemoveBasketProductsArgs>(
    REMOVE_BASKET_PRODUCTS,
    {},
  )
}

const UPLOAD_BASKET_FILE = gql`
  mutation uploadBasketFile($input: UploadBasketInput!) {
    uploadBasketFile(input: $input) {
      s3Key
      data {
        columnName
        previewData
        sheetName
        sheetIndex
      }
    }
  }
`

export const useUploadBasketFile = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'uploadBasketFile'>, MutationUploadBasketFileArgs>,
) => useMutation(UPLOAD_BASKET_FILE, baseOptions)

const PROCESS_BASKET_UPLOAD = gql`
  mutation processBasketUpload($input: ProcessBasketUploadInput!) {
    processBasketUpload(input: $input)
  }
`

export const useProcessBasketUpload = (
  baseOptions?: MutationHookOptions<Pick<Mutation, 'processBasketUpload'>, MutationProcessBasketUploadArgs>,
) => useMutation(PROCESS_BASKET_UPLOAD, baseOptions)

export const UPLOAD_BASKET_SUBSCRIPTION = gql`
  subscription UploadedBasket($id: String!) {
    uploadedBasket(id: $id) {
      basketId
    }
  }
`

const NORMALIZED_SUPPLIERS_IN_UPLOADED_FILE_COLUMN = gql`
  query normalizedSupplierNamesForUploadedFileColumn($input: FilterColumnInput!) {
    normalizedSupplierNamesForUploadedFileColumn(input: $input) {
      rawData
      normalizedSuppliers {
        inputSupplier
        retry {
          id
          name
        }
        normalizedCurvo
        type
      }
    }
  }
`

export function useNormalizedSupplierNamesForUploadedFileColumn(
  baseOptions: QueryHookOptions<
    Pick<Query, 'normalizedSupplierNamesForUploadedFileColumn'>,
    QueryNormalizedSupplierNamesForUploadedFileColumnArgs
  >,
) {
  return useQuery(NORMALIZED_SUPPLIERS_IN_UPLOADED_FILE_COLUMN, baseOptions)
}

const ARCHIVE_BASKET = gql`
  mutation archiveBasket($id: String!) {
    archiveBasket(id: $id) {
      ...SimpleBasketFragment
    }
  }
  ${SimpleBasketFragment}
`

export function useArchiveBasketMutation(
  options?: MutationHookOptions<Pick<Mutation, 'archiveBasket'>, MutationArchiveBasketArgs>,
) {
  return useMutation(ARCHIVE_BASKET, {
    ...options,
    refetchQueries: ['baskets'],
  })
}

const UNARCHIVE_BASKET = gql`
  mutation unarchiveBasket($id: String!) {
    unarchiveBasket(id: $id) {
      ...SimpleBasketFragment
    }
  }
  ${SimpleBasketFragment}
`

export function useUnarchiveBasketMutation(
  options?: MutationHookOptions<Pick<Mutation, 'unarchiveBasket'>, MutationUnarchiveBasketArgs>,
) {
  return useMutation(UNARCHIVE_BASKET, {
    ...options,
    refetchQueries: ['baskets'],
  })
}

const BASKETS_FACILITIES_STATS = gql`
  query basketsFacilitiesStats(
    $facilities: [Int!]
    $targetPercentile: Int!
    $sort: BasketSort
    $filter: BasketsFacilitiesChartFilter
    $pagination: Pagination!
  ) {
    basketsFacilitiesStats(
      facilityIds: $facilities
      targetPercentile: $targetPercentile
      sort: $sort
      filter: $filter
      pagination: $pagination
    ) {
      basketId
      basketName
      facilityId
      facilityName
      sumTotalSpend
      sumIqo25
      sumIqo50
      sumIqo75
      sumIqo90
      sumProjectedSpend
      sumIqoByPercentile
    }
  }
`

export const useBasketsFacilitiesStats = (baseOptions: QueryHookOptions<Pick<Query, 'basketsFacilitiesStats'>>) => {
  return useQuery(BASKETS_FACILITIES_STATS, baseOptions)
}

const COPY_CURVO_BASKET = gql`
  mutation copyCurvoBasket($input: CopyCurvoBasketInput!) {
    copyCurvoBasket(input: $input) {
      basket {
        ...SimpleBasketFragment
      }
      jobId
    }
  }
  ${SimpleBasketFragment}
`

export const useCopyCurvoBasket = (
  args?: MutationHookOptions<Pick<Mutation, 'copyCurvoBasket'>, MutationCopyCurvoBasketArgs>,
) => {
  return useMutation(COPY_CURVO_BASKET, args)
}

const FEATURED_BASKETS_QUERY = gql`
  query featuredBaskets($pagination: Pagination) {
    featuredBaskets(pagination: $pagination) {
      basket {
        ...SimpleBasketFragment
        insights12m {
          numItems
          totalSpend
          opportunity25
          opportunity50
          opportunity75
          opportunity90
          percentiles
          projectedSpend
          topIncumbent {
            totalSpend
            name
            percentage
          }
        }
        likelihoods {
          priceScoreLikelihoods
          totalSpend
        }
      }
      featuredTag
      featuredValue
    }
  }
  ${SimpleBasketFragment}
`

export function useFeaturedBasketsQuery(
  basicOptions: QueryHookOptions<Pick<Query, 'featuredBaskets'>, QueryFeaturedBasketsArgs>,
) {
  return useQuery(FEATURED_BASKETS_QUERY, basicOptions)
}

const UPDATE_SMART_BASKET_CONFIG = gql`
  mutation updateSmartBasketConfig($input: UpdateBasketSmartRulesConfigInput!) {
    updateSmartBasketConfig(input: $input) {
      ...BasketFragment
    }
  }
  ${BasketFragment}
`

export function useUpdateSmartBasketConfigMutation(
  options?: MutationHookOptions<Pick<Mutation, 'updateSmartBasketConfig'>, MutationUpdateSmartBasketConfigArgs>,
) {
  return useMutation(UPDATE_SMART_BASKET_CONFIG, options)
}
